import * as React from 'react';
import { ELEMENT_HYPER_LINK } from './defaults';
import { DolTooltip } from '../../../Tooltip/DolTooltip';
import { useClipboard } from 'use-clipboard-copy';
import { LinkActionTypeEnum, LinkSettingEnum } from '@doltech/utils/lib/constants';

// const internalLink = ['dolenglish.vn', 'dol.vn'];
//
// const isExternalLink = (href) => {
//   if (href.includes('tuhocielts.dolenglish.vn')) return true;
//   return !internalLink.some((i) => href.includes(i));
// };

/* eslint-disable react/jsx-no-target-blank */
const renderHyperLinkElementReadOnly2 =
  (_options) =>
  ({ attributes, children, element }) => {
    if (element.type === ELEMENT_HYPER_LINK) {
      const isNofollow = element?.linkSettings?.includes(LinkSettingEnum.NO_FOLLOW);
      const isNewTab = element?.linkSettings?.includes(LinkSettingEnum.OPEN_NEW_TAB);
      // const title = getSlateNodeAsTextString(children);
      if (element.url) {
        return (
          <a
            {...attributes}
            href={element.url}
            rel={isNofollow ? 'nofollow' : undefined}
            target={isNewTab ? '_blank' : undefined}
          >
            {children}
          </a>
        );
      }
      return <span>{children}</span>;
    }
    return null;
  };

export const renderHyperLinkElementReadOnly =
  (_options) =>
  ({ attributes, children, element }) => {
    if (element.type === ELEMENT_HYPER_LINK) {
      switch (element.linkAction) {
        case LinkActionTypeEnum.DIRECT:
          return renderHyperLinkElementReadOnly2(_options)({ attributes, children, element });
        case LinkActionTypeEnum.COPY_TO_CLIPBOARD:
          return renderCopyToClipboardLink(_options)({ attributes, children, element });
        default:
          return renderHyperLinkElementReadOnly2(_options)({ attributes, children, element });
      }
    }
    return null;
  };

const renderCopyToClipboardLink =
  (_options) =>
  ({ attributes, children, element }) => {
    const clipboard = useClipboard({
      copiedTimeout: 2000,
    });

    return (
      <DolTooltip
        className="copy-link-btn-tooltip"
        title={clipboard.copied ? 'Copied' : 'Click to copy'}
        placement="bottom"
        trigger={['hover']}
      >
        <span
          onClick={(e) => {
            // need to stopPropagation to prevent parent block when click a href
            e.stopPropagation();
            clipboard.copy(element.url);
          }}
          className="hyper-link-element"
          {...attributes}
        >
          {children}
        </span>
      </DolTooltip>
    );
  };
