import { SlatePlugin } from '../../../../slatePlugins/components/core/types';
import { ELEMENT_HYPER_LINK } from './defaults';
import { renderHyperLinkElementReadOnly } from './renderElementReadOnly';

export const HyperLinkElementReadOnlyPlugin = (options?): SlatePlugin => {
  return {
    renderElement: renderHyperLinkElementReadOnly(options),
    inlineTypes: [ELEMENT_HYPER_LINK],
  };
};
